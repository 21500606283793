.from-top-animation {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.5s, transform 0.5s;
}

.from-top-enter-active,
.from-top-enter-done {
  opacity: 1;
  transform: translateY(0%);
}

.from-top-exit-active,
.from-top-exit-done {
  opacity: 0;
  transform: translateY(-100%);
}
